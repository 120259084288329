<template>
  <div class="zoom--wrapper" @click.self="TOGGLE_ZOOM_MODAL">
    <img :src="src" alt="/">
    <div class="close" @click="TOGGLE_ZOOM_MODAL">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path d="M3 3L21 21M21 3L3 21" stroke="#B0B0B0" stroke-linejoin="round"/>
      </svg>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "ImageZoomModal",
  props: {
    src: String
  },
  methods: {
    ...mapActions([
      'TOGGLE_ZOOM_MODAL'
    ]),
  },
}
</script>

<style scoped lang="scss">
.zoom--wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  display: grid;
  place-items: center;
  background: rgba(39, 39, 39, .9);
}

.close {
  position: absolute;
  top: 2vw;
  right: 2vw;
  background-color: transparentize(#B0B0B0, .9);
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  cursor: pointer;

  svg {
    width: 40%;
    height: 40%;

    path {
      stroke: transparentize(white, .5);
      stroke-width: 2px;
    }
  }
}


img {
  width: 100%;
  max-width: 80vw;
  height: 100%;
  max-height: 80vh;
  cursor: default;
  object-fit: contain;
  vertical-align: unset;
  border-radius: unset;
  margin: 0;
  @media (max-width: 820px) and (orientation: landscape) {
    pointer-events: none;
    max-width: 95vw;
    max-height: 95vh;
  }
}

</style>