<template>
  <div class="FAQ-page">
    <transition name="default">
      <image-zoom-modal v-if="GET_ZOOM_MODAL_STATUS" :src="imageSrc"/>
    </transition>
    <div class="container">
      <div class="FAQ-page__wrapper">
        <div class="head">
          <h1>
            <svg width="179" height="38" viewBox="0 0 179 38" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M0.5 1.46V35H9.2V23.6H91.82V15.98H9.2V9.14H96.02V1.46H0.5ZM122.696 1.46H111.356L99.1161 35H107.996L109.916 29.6H124.136L126.116 35H134.936L122.696 1.46ZM117.056 10.22L121.376 21.98H112.736L117.056 10.22ZM177.296 18.26C177.296 7.64 168.776 0.979998 157.016 0.979998C145.256 0.979998 136.736 7.64 136.736 18.26C136.736 28.82 145.256 35.54 157.016 35.54C160.856 35.54 164.336 34.76 167.396 33.2L172.136 37.88L178.316 31.7L174.056 27.5C176.156 24.5 177.296 21.98 177.296 18.26ZM168.356 18.26C168.356 25.22 164.636 27.62 157.016 27.62C149.396 27.62 145.676 25.22 145.676 18.26C145.676 11.24 149.396 8.9 157.016 8.9C164.636 8.9 168.356 11.24 168.356 18.26Z"
                  fill="#E2FF31"/>
            </svg>
          </h1>
        </div>
        <div class="body" ref="pageContent" v-if="dataReady" v-html="this.content">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ImageZoomModal from "@/components/UIElements/ImageZoomModal";

export default {
  name: "FAQ",
  components: {ImageZoomModal},
  data() {
    return {
      dataReady: false,
      imageSrc: '',
      content: ''
    }
  },
  methods: {
    ...mapActions([
      'FETCH_FAQ',
      'TOGGLE_ZOOM_MODAL'
    ]),
    toggleModal(src) {
      this.imageSrc = src
      this.TOGGLE_ZOOM_MODAL()
    },
    getFAQ(data) {
      return this.content = data
    }
  },
  computed: {
    ...mapGetters([
      'GET_ZOOM_MODAL_STATUS'
    ])
  },
  async mounted() {
    this.dataReady = false
    this.getFAQ(await this.FETCH_FAQ())
    this.dataReady = true
    this.$nextTick(() => this.$refs.pageContent.querySelectorAll("img").forEach(img => img.addEventListener('click', (e) => this.toggleModal(e.target.src))))
  },
  beforeDestroy() {
    this.$refs.pageContent.querySelectorAll("img").forEach(img => img.removeEventListener('click', (e) => this.toggleModal(e.target.src)))
  }
}
</script>

<style scoped>

</style>